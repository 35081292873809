import React from "react";
import {
  SUPPORT_NETWORKS,
  TOTAL_BYTES,
  TOTAL_TRANSACTIONS_COUNT,
} from "../../constants/Parametrs";

export default function TransactionParametrs({parametrs}) {
  return (
    <div className="parameters">
      {parametrs && parametrs.map((parametr, key) => (
        <section className="parameters__item" key={key}>
          <img src={parametr.img} alt="" />
          <span className="parameters__item-title">{parametr.title}</span>
          <span className="parameters__item-value">{parametr.value}</span>
        </section>
      ))}
    </div>
  );
}
