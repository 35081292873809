import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cutLongString } from "../../utils/StringUtil";
import CopyButton from "./CopyButton";
import { getNetworkAddressUrl } from "../../utils/Networks";

export default function TransactionTableRow({ transaction }) {
  return (
    <div className="table__row">
      <div className="table__cell txn-hash-col">
        <span className="table__cell-title">Txn Hash</span>
        <div className="table__cell-cnt">
          <img src="/img/asterizm-table-icon.svg" alt="" />
          <Link to={`detail/${transaction.id}`}>
            {cutLongString(transaction.txHash)}
            <span className="tooltip">{transaction.txHash}</span>
          </Link>
          <CopyButton copyStr={transaction.txHash} />
        </div>
      </div>
      <div className="table__cell status-col">
        <span className="table__cell-title">Status</span>
        <span className={"status-badge " + transaction.statusClass}>
          {transaction.status}
        </span>
      </div>
      <div className="table__cell from-col">
        <span className="table__cell-title">From</span>
        <div className="table__cell-cnt">
          <div className="table__icon-cnt">
            <img src={transaction.fromNetworkLogo} alt="" />
            <span className="tooltip">{transaction.fromNetworkTitle}</span>
          </div>
          <span className="table__hash">
            <a
              href={getNetworkAddressUrl(
                transaction.networkFrom,
                transaction.from
              )}
              target="_blank"
            >
              {cutLongString(transaction.from)}
            </a>
            <CopyButton copyStr={transaction.from} />
            <span className="tooltip">{transaction.from}</span>
          </span>
        </div>
      </div>
      <div className="table__cell to-col">
        <span className="table__cell-title">To</span>
        <div className="table__cell-cnt">
          <div className="table__icon-cnt">
            <img src={transaction.toNetworkLogo} alt="" />
            <span className="tooltip">{transaction.toNetworkTitle}</span>
          </div>
          <span className="table__hash">
            <a
              href={getNetworkAddressUrl(transaction.networkTo, transaction.to)}
              target="_blank"
            >
              {cutLongString(transaction.to)}
            </a>
            <CopyButton copyStr={transaction.to} />
            <span className="tooltip">{transaction.to}</span>
          </span>
        </div>
      </div>
      <div className="table__cell time-col">
        <span className="table__cell-title">Time</span>
        <span className="table__cell-cnt">{transaction.time}</span>
      </div>
    </div>
  );
}
