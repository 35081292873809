export const TestTransferItem = {
  id: "aa",
  txHash: "aaa",
  status: "sent",
  subStatus: null,
  sender: "0x417a852E28599623415102fA2138B61eEb848124",
  receiver: "0x812B2df5C33f40fe4CF349056c26D42152DBe61b",
  bytesSum: 160,
  transactionTime: "2023-07-27T17:40:36Z",
  networkFrom: {
    id: "ba6c9457-1b22-11ee-b4fd-0242ac1d0018",
    title: "Arbitrum",
    symbol: "ARB",
    chainId: "42161",
  },
  networkTo: {
    id: "ba6c10aa-1b22-11ee-b4fd-0242ac1d0018",
    title: "BSC",
    symbol: "BSC",
    chainId: "56",
  },
};
