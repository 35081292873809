import { useEffect, useState } from "react";
import TransactionForm from "./TransactionForm";
import TransactionParametrs from "./TransactionParametrs";
import TransactionTable from "./TransactionTable";
import { LoadTransactionList } from "../../hooks/LoadTransactionList";

export default function TransactionList() {
  const [formData, setFormData] = useState({
    filter: "",
    page: 1,
    makeLoad: true,
  });

  useEffect(() => {}, [formData.filter]);

  const toggleFilter = (makeLoad) => {
    setFormData((formData) => ({
      ...formData,
      makeLoad: makeLoad,
    }));
  };


  const { transactions, pageData, isLoading,setIncomeTransaction,netWorkstats,setLoadTestTransfer } = LoadTransactionList(
    formData,
    toggleFilter,
    
  );

  const onFilterHandle = () => {
    toggleFilter(true);
  };

  const gotoPrev = () => {
    setFormData((formData) => ({
      ...formData,
      page: formData.page - 1,
      makeLoad: true,
    }));
  };

  const gotoNext = () => {
    setFormData((formData) => ({
      ...formData,
      page: formData.page + 1,
      makeLoad: true,
    }));
  };

  const gotoPage = (e) =>{
    console.log(e.target.value,'e');
    setFormData((formData) => ({
      ...formData,
      page: e.target.value,
      makeLoad: true,
    }));
  }
  return (
    <>
      <TransactionParametrs parametrs={netWorkstats} />
      <TransactionForm
        formData={formData}
        setFormData={setFormData}
        onFilterHandle={onFilterHandle}
      />
      <TransactionTable
        transactions={transactions}
        pageData={pageData}
        gotoPrev={gotoPrev}
        gotoNext={gotoNext}
        isLoading={isLoading}
        gotoPage={gotoPage}
      />
    </>
  );
}
