import { useEffect, useState } from "react";
import axios from "../app/axios";
import { formatTime, timeSince } from "../utils/DateUtil";
import { convertServerStatus, convertServerStatusToClass } from "../utils/Status";
import { loadTransactionDetailApi } from "../app/apiMethod";
import { getNetworkImageByChainId } from "../utils/Networks";
import { TransactionSubStatuses } from "../constants/SubStatusText";
import { ethers } from "ethers";

export function LoadTransactionDetail(transactionId) {
  const [transactionData, setTransactionData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    getDetail();
  }, [transactionId]);

  const transferSubStatus = (subStatus) => {
    if (!subStatus) {
      return '-';
    }

    switch (subStatus) {
      case TransactionSubStatuses.sent:
        return 'Transaction was sent';
      case TransactionSubStatuses.initiated:
        return 'Transfer was initiated';
      case TransactionSubStatuses.smallFee:
        return 'Not enough fee was transferred';
      case TransactionSubStatuses.txReverted:
        return 'Transaction was reverted';
      case TransactionSubStatuses.resendsTriesReached:
        return 'Transaction was resends too much times';
      case TransactionSubStatuses.networkTimeout:
        return 'Transaction was not sent (network timeout)';
      case TransactionSubStatuses.sendTimeout:
        return 'Transaction was not sent (send timeout)';
      default:
        const array = subStatus.split('_');
        array[0] = array[0].charAt(0).toUpperCase() + array[0].slice(1);
        return array.join(' ');
    }
  };


  const getDetail = async () => {
    if (transactionId) {
      setLoaded(false);

      try {
        let result = await loadTransactionDetailApi(transactionId);
        let transaction = {
          id: result.id,
          statusClass: convertServerStatusToClass(result.status),
          status: convertServerStatus(result.status),
          substatus: transferSubStatus(result.subStatus),
          transferHash: result.transferHash,
          srcNetworkTitle:result.networkFrom.title,
          srcNetworkLogo: getNetworkImageByChainId(
            parseInt(result.networkFrom.chainId)
          ),
          dstnNetworkTitle:result.networkTo.title,
          dstnNetworkLogo: getNetworkImageByChainId(
            parseInt(result.networkTo.chainId)
          ),
          srcTxHash: result.srcTxHash,
          dstnTxHash: result.dstnTxHash,
          dstnError: result.dstnError,
          sender: result.sender,
          receiver: result.receiver,
          srcBlockNumber: result.srcBlockNumber,
          srcBlockHash: result.srcBlockHash,
          //Apr 25th, 2023 6:02:11 PM +UTC
          transactionTime: formatTime(
            result.transactionTime,
            "MMM Do, YYYY h:mm:ss A z"
          ),
          messageNonce: result.messageNonce,
          gasPrice: ethers.formatUnits(result.gasPrice,result.networkFrom.coinDecimals) + " " + (result.networkFrom.coinSymbol || ''),
          gasPriceUsd: parseFloat(result.gasPriceUsd || '0').toFixed(2),
          payload: result.payload,
          networkFrom: result.networkFrom,
          networkTo: result.networkTo
        };
        setTransactionData(transaction);
      } catch (error) {

        console.log(error,'error')
      }
      setLoaded(true);
    }
  };

  return { transactionData, isLoaded };
}
