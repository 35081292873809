import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default axios.create({
  baseURL: makeUrl(),
  // baseURL: 'https://testscan.asterizm.io/api/1.0/',
  // baseURL:`http://api.crypto.prostories.lan/1.0/`,
  //   crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'ApiKey' : process.env.REACT_APP_API_KEY //'qwerty'
  },

  //   mode: 'no-cors',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json',
  //   },
  //   withCredentials: true,
  //   credentials: 'same-origin',
})

export function makeUrl(){
  return window.location.origin + '/api/1.0/';
}

export function makeWebsocketUrl(){
  return "wss://"+window.location.hostname + '/ws';
}
