import moment from "moment";

export function timeSince(date) {
  // var seconds = Math.floor((new Date() - date) / 1000);
  // console.log(date,'date')
  let duration = moment.duration(moment().diff(date));
  var seconds = Math.floor(duration.asSeconds());
  // console.log(duration,duration.asSeconds(),date,'date')

  //   var interval = seconds / 31536000;

  //   if (interval > 1) {
  //     return Math.floor(interval) + " years";
  //   }
  //   interval = seconds / 2592000;
  //   if (interval > 1) {
  //     return Math.floor(interval) + " months";
  //   }
  let prefix = " ago";
  const oneMinute = 60;
  let interval = seconds / (oneMinute * 60 * 24);
  if (interval >= 1) {
    return moment(date).format("MMM D, h:mm:ss A z"); // May 25th 2023, 6:47:42 am
  }

  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + (interval === 1 ? "hour" : " hours") + prefix;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes" + prefix;
  }
  return "Now"; //Math.floor(seconds) + " seconds";
}

export function formatTime(date, format = "MMM D, k:mm:ss") {
  return moment(date).format(format);
}
