import axios from "./axios";

export const loadTransactionListApi = async (page, perPage) => {
  let responce = await axios.get(
    "transaction/list?" + "page=" + page + "&perPage=" + perPage
  );
  return responce.data;
};

export const loadTransactionDetailByTransferHashApi = async (transferHash) => {
  let response = await axios.get("transaction/detail-by-transfer-hash?transferHash=" + transferHash);
  return response.data;
};

export const loadTransactionDetailApi = async (id) => {
  let responce = await axios.get("transaction/detail?id=" + id);
  return responce.data;
};

export const loadCommonData = async () => {
  let responce = await axios.get("transaction/common-data");
  return responce.data;
};
