import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'TopMenu',
  initialState: {
    showMobileMenu: false,
  },
  reducers: {
    toggleMobileMenu: (state) => {
      state.showMobileMenu = !state.showMobileMenu
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleMobileMenu } = menuSlice.actions

export default menuSlice.reducer
