import React from "react";
import Menu from "./Menu";
import { useDispatch } from "react-redux";
import { toggleMobileMenu } from "../../features/menu/menuSlice";
import HeaderFilter from "./HeaderFilter";
import { useLocation } from "react-router-dom";

export default function HeaderPanel({ customClass }) {
  let dispatch = useDispatch();

  const { pathname } = useLocation();
  const isDetailPage = pathname === "/detail";

  return (
    <>
      <header className={"header " + customClass}>
        <div className="wrap">
          <div className="header__cnt">
            <a href="/">
              <img
                className="header__logo"
                src="/img/asterizm-scan-logo.svg"
                alt=""
              />
            </a>

            <Menu customClass={"header__menu"}></Menu>
            {isDetailPage && <HeaderFilter />}
            <button
              className="menu-btn menu-open"
              onClick={() => dispatch(toggleMobileMenu())}
            >
              <img src="/img/burger-icon.svg" alt="" />
            </button>
          </div>
        </div>
      </header>
    </>
  );
}
