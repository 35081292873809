import React, { useState } from "react";
import { isCorrectTxHash } from "../../utils/TransactionUtil";

export default function TransactionForm({
  formData,
  setFormData,
  onFilterHandle,
}) {
  const fieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsCheckFilter(false);
  };

  const [isCheckFilter, setIsCheckFilter] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const isCorrect = formData.filter == '' ||  isCorrectTxHash(formData.filter);

  const onFilterClick = () => {
    let isCorrect = formData.filter == '' || isCorrectTxHash(formData.filter);
    setIsCheckFilter(true);
    if (isCorrect) {
      onFilterHandle();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={
          "search-input " + (isCheckFilter && !isCorrect ? "error" : "")
        }
      >
        <input
          type="text"
          placeholder="Search by  Txn Hash"
          name="filter"
          value={formData.filter}
          onChange={fieldChange}
        />
        <button className="search-input__btn" onClick={() => onFilterClick()}>
          <img src="/img/search-icon.svg" alt="" />
        </button>
        {isCheckFilter && !isCorrect && (
          <span className="search-input__error-message">
            This is an invalid search string. Please check your input or try
            another search.
          </span>
        )}
      </div>
    </form>
  );
}
