import React from "react";
import TransactionList from "../components/transactions/TransactionList";

export default function Home() {
  return (
    <main className="main" style={{ background: "none" }}>
      <div className="gas-station-wrapper">
        <TransactionList />
      </div>
    </main>
  );
}
