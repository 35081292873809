import React from "react";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileMenu } from "../../features/menu/menuSlice";

export default function HeaderFilter() {

  return (
    <div class="header__search-input search-input">
      <input type="text" placeholder="Search by Address / Txn Hash / Block" />
      <button class="search-input__btn">
        <img src="/img/search-icon.svg" alt="" />
      </button>
    </div>
  );
}
