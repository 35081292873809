import React, { useState } from "react";
import { GITHUB_URL, TELEGRAM_URL, TWITTER_URL } from "../../constants/ExternalLinks";

export default function Footer() {
  return (
    <footer className="footer ">
      <div className="wrap">
        <div className="footer__container">
          <img src="/img/asterizm-logo.svg" alt="" />
          <span className="footer__copyrights">
            © {new Date().getFullYear()} Asterizm Scan. All rights reserved
          </span>
          <div className="footer__socials">
            <a href={TWITTER_URL}>
              <img src="/img/twitter-footer-icon.svg" alt="" />
            </a>
            <a href={TELEGRAM_URL}>
              <img src="/img/telegram-footer-icon.svg" alt="" />
            </a>
            <a href={GITHUB_URL}>
              <img src="/img/github-footer-icon.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
