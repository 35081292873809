import React from "react";
import { useSelector } from "react-redux";
import { API_DOC_URL, MORE_DETAILS } from "../../constants/ExternalLinks";

export default function Menu({ customClass }) {
  // const isMobile = isMobileDevice();

  return (
    <>
      <nav className={customClass + " menu " }>
        <ul className="menu__list">
          <li className="menu__item">
            <a className="menu__link" href={API_DOC_URL}>
              Docs
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href={MORE_DETAILS}>
              I want to know more
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
