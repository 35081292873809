import React from "react";
import TransactionPagination from "./TransactionPagination";
import TransactionTableRow from "./TransactionTableRow";

export default function TransactionTable({
  transactions,
  pageData,
  gotoPrev,
  gotoNext,
  isLoading,
  gotoPage
}) {
  return (
    <>
      <div className="table">
        <header className="table__header">
          <span className="txn-hash-col">Txn Hash</span>
          <span className="status-col">Status</span>
          <span className="from-col">From</span>
          <span className="to-col">To</span>
          <span className="time-col">Time</span>
        </header>

        <div className="table__body">
          {isLoading.current && (
            <div className="table__empty">
              <div className="loader-block">
                <span className="loader"></span>
                <span>Loading...</span>
              </div>
            </div>
          )}
          {!isLoading.current && transactions.length == 0 && (
            <div className="table__empty">
              <img src="/img/no-transactions-icon.svg" alt="" />
              <h3 className="block-title">No transactions yet</h3>
              <span className="table__empty-caption">
                Completed transactions will be
                <br />
                displayed here.
              </span>
            </div>
          )}
          {!isLoading.current && transactions.length > 0 && (
            <div className="table__wrap">
              <div className="table__content">
                {transactions.map((transaction, index) => (
                  <TransactionTableRow transaction={transaction} key={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {pageData.total > 0 && <TransactionPagination
        pageData={pageData}
        gotoPrev={gotoPrev}
        gotoNext={gotoNext}
        gotoPage={gotoPage}
        isLoading={isLoading.current}
      />}
    </>
  );
}
