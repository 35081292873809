import React from "react";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileMenu } from "../../features/menu/menuSlice";

export default function Header() {
  // const isMobile = isMobileDevice();
  const isShowMenu = useSelector((state) => state.menuManagment.showMobileMenu);
  let dispatch = useDispatch();

  return (
    <>
      <div className={"mobile-menu " + (isShowMenu ? "active" : "")}>
        <header className="mobile-menu__header header">
          <div className="header__cnt">
            <img
              className="header__logo"
              src="/img/asterizm-scan-logo.svg"
              alt=""
            />
            <button className="menu-btn menu-close" onClick={() => dispatch(toggleMobileMenu())}>
              <img src="/img/close-icon.svg" alt="" />
            </button>
          </div>
        </header>
        <Menu customClass={"mobile-menu__menu"}></Menu>
      </div>
    </>
  );
}
