import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from './routes/routes';
import Layout from './layout/Layout';
import "./assets/scss/style.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} element={<route.element />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
