import React from "react";
import Menu from "../layout/Header/Menu";
import { useDispatch } from "react-redux";
import { toggleMobileMenu } from "../features/menu/menuSlice";
import HeaderPanel from "../layout/Header/HeaderPanel";

export default function Hero() {
  // const isMobile = isMobileDevice();
  let dispatch = useDispatch();
  return (
    <div className="hero">
      <HeaderPanel></HeaderPanel>
      <div className="wrap">
        <div className="hero__cnt">
          <h1 className="primary-title">Asterizm cross-chain scan</h1>
        </div>
      </div>
    </div>
  );
}
