import {DEF_MAINNET_CHAINS, SOLANA_DEVNET_CHAIN_ID} from "../constants/ProdNetworks";
import {CHAIN_TYPE_SOL, CHAIN_TYPE_TON} from "../constants/ChainTypes";

const CHAIN_TYPE_TVM = "tvm";

export function getNetworks() {
  return DEF_MAINNET_CHAINS;
}

function solanaCluster(chainId) {
  return chainId === SOLANA_DEVNET_CHAIN_ID ? '?cluster=devnet' : '';
}

const networkImageFolder = "/img/networks-icons/";

export function getNetworkImageByChainId(networkChainId) {
  let item = getNetworks().find((v) => v.chainId === networkChainId);
  return networkImageFolder + (item ? item.img : "default-icon.svg");
}

export function getNetworkExplorer(network) {
  return network.blockExplorerUrls[0];
}

export function getNetworkTransactionUrl(backNetwork, transactionHash) {
  try {
    let network = getNetworks().find((v) => v.chainId == backNetwork.chainId);
    let baseUrl = getNetworkExplorer(network);

    if (network.chainType === CHAIN_TYPE_TVM) {
      return baseUrl + '/transactions/' + transactionHash;
    }

    if (network.chainType === CHAIN_TYPE_TON) {
      return baseUrl + '/transaction/' + transactionHash;
    }

    if (network.chainType === CHAIN_TYPE_SOL) {
      return baseUrl + '/tx/' + transactionHash + solanaCluster(network.chainId);
    }

    return baseUrl + '/tx/' + transactionHash;
  } catch (error) {
    return "#";
  }
}

export function getNetworkAddressUrl(backNetwork, address) {
  try {
    let network = getNetworks().find((v) => v.chainId == backNetwork.chainId);
    let baseUrl = getNetworkExplorer(network);

    if (network.chainType === CHAIN_TYPE_TVM) {
      return baseUrl + "/" + "accounts/" + address;
    }
    if (network.chainType === CHAIN_TYPE_TON) {
      return baseUrl + "/" + address;
    }

    if (network.chainType === CHAIN_TYPE_SOL) {
      return baseUrl + '/address/' + address + solanaCluster(network.chainId);
    }


    return baseUrl + "/address/" + address;
      
  } catch (error) {
    return '#';    
  }
}
