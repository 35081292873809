import { useState } from "react";
import { copyContent } from "../../utils/Brauser";

export default function CopyButton({ copyStr }) {

    const [isCopied, setIsCopied] = useState(false);
  
    const onCopyHandler = () =>{
        copyContent(copyStr);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
      }
    
    return (
        <button className={'copy-btn ' +(isCopied ? 'success' : "")} onClick={() => onCopyHandler()}></button>
    )
}
  