import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import Hero from "../components/Hero";
import HeaderPanel from "./Header/HeaderPanel";

function Layout() {
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";

  return (
    <>
      <Header />
      {isHomePage && <Hero />}
      {!isHomePage && <HeaderPanel customClass={"header_inner"} />}
      <main className="main">
        <div className="wrap">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
}
export default Layout;
